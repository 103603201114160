import React, { useState, useEffect } from 'react'
import Content from "./Content"
import Header from './Header';
import Buttons from './Buttons';
import MailWall from './services/MailWall';

import { CircularProgress, Box } from '@mui/material';

import { useGetMasterData } from '../api/master';

export default function Menu() {

  const [formOpen, setFormOpen] = useState(false);
    
  const handleCardClick = (clickedSections, index) => {
    console.log("Clicked on:", clickedSections, index);
    
  };

  //master
  const { masterData, masterDataLoading } = useGetMasterData();
  console.log(masterData)

  useEffect(() => {
    if (masterData && !masterDataLoading && masterData.services.emailWall.isActive) {
      setFormOpen(true); // Open the MailWall once masterData is loaded
      console.log("Hola")
    }
  }, [masterData, masterDataLoading]);

  const handleCloseForm = () => {
    setFormOpen(false); // Close MailWall after submission
  };
  

  return (
    <div>
      {masterData ? (
        <>
        {masterData && !masterDataLoading ? (
          <Header
            backgroundColor={masterData.headerStyle.backgroundColor}
            logoSrc={masterData.headerStyle.logoSrc}
            sectionHeight = {masterData.headerStyle.sectionHeight}
            sectionPosition = {masterData.headerStyle.sectionPosition}
            sectionBottomBorder = {masterData.headerStyle.sectionBottomBorder}
            sectionBoxShadow = {masterData.headerStyle.sectionBoxShadow}
            boxMargin = {masterData.headerStyle.boxMargin}
            maxLogoHeight = {masterData.headerStyle.maxLogoHeight}
            backgroundImage={masterData.headerStyle.backgroundImage}
            />
          ):(
            <></>
          )}
          
          {masterData && !masterDataLoading ? (
            <Buttons icons={masterData.buttons}/>
          ):(
            <></>
          )}
          
          {masterData && !masterDataLoading ? (
            <Content 
              defaultSelectedSection = {masterData.categoryStyle.defaultSelectedSection}
              sections={masterData.sections} 
              onCardClick={handleCardClick} 
              backgroundColor={masterData.categoryStyle.backgroundColor}
              
              cardContainerPadding= {masterData.categoryStyle.cardContainerPadding}
              cardContainerMarginTop= {masterData.categoryStyle.cardContainerMarginTop}
              cardContainerHeight= {masterData.categoryStyle.cardContainerHeight}
              cardContainerPosition= {masterData.categoryStyle.cardContainerPosition}
              cardContainerBorderBottom= {masterData.categoryStyle.cardContainerBorderBottom}

              highlightSettings={masterData.categoryStyle.highlightSettings}
              selectedShadow={masterData.categoryStyle.selectedShadow}
              standingShadow={masterData.categoryStyle.standingShadow}
              textSize = {masterData.categoryStyle.textSize}
              fontFamily = {masterData.categoryStyle.fontFamily}
              textColor = {masterData.categoryStyle.textColor}


              cardRatio= {masterData.categoryStyle.cardRatio}
              cardHeight= {masterData.categoryStyle.cardHeight}
              cardCursor= {masterData.categoryStyle.cardCursor}
              cardBorderRadius= {masterData.categoryStyle.cardBorderRadius}
              cardTransition= {masterData.categoryStyle.cardTransition}
              cardMarginRight = {masterData.categoryStyle.cardMarginRight}
              cardPaddingRight = {masterData.categoryStyle.cardPaddingRight}

              contentMarginTop={masterData.categoryStyle.contentMarginTop}
              contentHeight={masterData.categoryStyle.contentHeight}
            />
          ):(
            <></>
          )}
          <MailWall
            open={formOpen}
            onClose={handleCloseForm}
            data = {masterData.services.emailWall}
            styles={{
              padding: '16px',
              backgroundColor: '#ffffff',
            }}
          />
          </>
    ):(
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    )}
      
  </div>
  )
}
