import { useMemo } from 'react';
import useSWR  from 'swr';
import { fetcher } from '../utils/axios';
import { useParams } from 'react-router-dom';

export function useGetMasterData() {
  const { catalogId } = useParams();

    const { data, isLoading, error, isValidating } = useSWR(`getMasterData?&catalogId=${catalogId}`, fetcher, {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false
    });

  const memoizedValue = useMemo(
    () => ({
      masterData: data?.data,
      masterDataLoading: isLoading,
      masterDataError: error,
      masterDataValidating: isValidating,
      masterDataEmpty: !isLoading
    }), [data, isLoading, error, isValidating]);

  return memoizedValue;
  }