import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  MenuItem,
  Box
} from '@mui/material';
import useDataSave from '../../api/dataSave';

const renderExtras = (item, formData, formErrors, handleChange) => {
  switch (item.type) {
    case 'dropdown':
      return (
        <TextField
          key={item.text} // Ensure a unique key is used
          fullWidth
          label={item.text}
          name={item.text}
          value={formData[item.text] || ''}
          onChange={handleChange}
          select
          error={!!formErrors[item.text]}
          helperText={formErrors[item.text]}
          sx={{ marginBottom: 2 }}
        >
          {item.fields.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </TextField>
      );
    case 'text': // New case for text type
      return (
        <TextField
          key={item.name}
          fullWidth
          label={item.name}
          name={item.name}
          value={formData[item.name] || ''}
          onChange={handleChange}
          error={!!formErrors[item.name]}
          helperText={formErrors[item.name]}
          sx={{ marginBottom: 2 }}
        />
      );
    default:
      return null;
  }
};

export default function MailWall({ open, onClose, styles, data }) {
  const [formData, setFormData] = useState({ email: '', name: '' });
  const [formErrors, setFormErrors] = useState({});
  const { saveData, loading, error } = useDataSave();
  const { catalogId } = useParams();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateForm = () => {
    const errors = {};
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Regex pattern for validating email

    if (!formData.email) {
      errors.email = "Email is required";
    } else if (!emailPattern.test(formData.email)) {
      errors.email = "Invalid email format";
    }

    if (!formData.name) errors.name = "Name is required";

    // Validate additional fields dynamically
    data.extras.forEach((extra) => {
      if (extra.type === 'text' && !formData[extra.name]) {
        // Validation for text type to be non-empty
        errors[extra.name] = `${extra.name} is required`;
      } else if (extra.type === 'dropdown' && extra.required && !formData[extra.name]) {
        errors[extra.name] = `${extra.text} is required`;
      }
    });

    return errors;
  };

  const handleSubmit = async () => {
    const errors = validateForm();
    if (Object.keys(errors).length === 0) {
      console.log('Form submitted', formData);
  
      const isSuccess = await saveData(catalogId, formData); // Use return value
  
      if (isSuccess) {
        console.log('Data saved successfully');
        window.scrollTo(0, 0); // Scroll to the top of the page
        onClose();
      } else {
        console.error('Error saving data:', error);
      }
    } else {
      setFormErrors(errors);
    }
  };  

  return (
    <Dialog 
      open={open} 
      onClose={(event, reason) => {
        if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
          onClose();
        }
      }} 
      fullWidth 
      maxWidth="sm">
      <DialogTitle>{data.mainText}</DialogTitle>
      <DialogContent>
        <Box sx={{ ...styles }}>
          <TextField
            fullWidth
            label={data.email}
            name="email"
            value={formData.email}
            onChange={handleChange}
            error={!!formErrors.email}
            helperText={formErrors.email}
            sx={{ marginBottom: 2 }}
          />
          <TextField
            fullWidth
            label={data.name}
            name="name"
            value={formData.name}
            onChange={handleChange}
            error={!!formErrors.name}
            helperText={formErrors.name}
            sx={{ marginBottom: 2 }}
          />
          {data.extras.length > 0 ? (
            data.extras.map((item) => renderExtras(item, formData, formErrors, handleChange))
          ) : (
            <></>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSubmit} variant="contained" color="primary" disabled={loading}>
          {data.btnText}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
