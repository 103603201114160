import { useState, useCallback } from 'react';
import { fetcher } from '../utils/axios'; // Import the updated fetcher

const useDataSave = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const saveData = useCallback(async (catalogId, values) => {
    setLoading(true);
    setError(null);
    setSuccess(false);

    try {
      console.log(catalogId);
      console.log(values);

      // Using fetcher to send a POST request with catalogId and values
      const response = await fetcher([
        'setMailWallEntry',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json', // Set content type to JSON
          },
          data: {
            catalogId,
            values,
          },
        },
      ]);
      
      if (response) {
        setSuccess(true);
        return true; // Return true on success
      }
    } catch (err) {
      setError(err.message);
      return false; // Return false on error
    } finally {
      setLoading(false);
    }
  }, []);

  return { saveData, loading, error, success };
};

export default useDataSave;
