import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Menu from './components/Menu';
import './App.css'

function App() {

  return (
    <BrowserRouter>
        <Routes>
          <Route path='/:catalogId' Component={Menu}></Route>
        </Routes>
    </BrowserRouter>
  );
}

export default App;
