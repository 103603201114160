import React from 'react';
import { Box } from '@mui/material';
import './scoped-styles.css'; // Import your scoped CSS
import './quill.snow.css';

const ItemText = ({
  text = 'Arizolo, una pieza de arte <strong>inigualiable</strong> <u><em>desarrollada</em></u> por los mejores <span style="color:#842689">y más talentosos</span> escultores mexicanos<ol><li>Primero</li><li>Segundo</li><li>Tercero</li></ol>',
  margin = '3vh 10vw 3vh 10vw',
}) => {
  return (
    <Box 
      margin={margin}
    >
      <div
        className="no-tailwind-base ql-editor"
        dangerouslySetInnerHTML={{ __html: text }}
      ></div>
    </Box>
  );
};

export default ItemText;
